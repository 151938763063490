@mixin surveyViewDetailsBtn {
  display: flex;
  align-items: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 40px;
  cursor: pointer;
}

.picker-input__clear {
  display: none;
}

.calendar__day:hover {
  color: #fff;
}

.picker__container {
  top: 351px !important;
}
.date-input-container {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 100%;
  max-width: 300px; /* Adjust as per your design */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 3px;

  .custom-date-input {
    font-family: Arial, sans-serif;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .custom-date-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .custom-date-input::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.create-survey-outer-wrap {
  margin: 36px 0 125px;

  .create-survey-wrap {
    padding: 22px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(7, 7, 7, 0.1);
    border: 1px solid gray;
  }

  .surveyNam {
    .form-control {
      padding: 10px;
      border-radius: 10px;
    }

    .surveyNam-label {
      color: rgba(54, 55, 56, 0.8);
      font-size: 16px;
      margin-bottom: 14px;
      font-weight: 600;
    }
  }

  .survey-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    .survey-header-main {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    h1 {
      font-weight: bold;
      font-size: 17px;
    }

    em {
      font-style: italic;
      font-weight: 400;
      color: gray;
    }

    .back-icon {
      cursor: pointer;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 3px;
      font-size: 25px;

      &:hover {
        background-color: #414241;
        color: white;
      }
    }

    .question-count {
      display: flex;
      gap: 8px;
      .question-btn-edit {
        @include surveyViewDetailsBtn;
        padding: 8px 15px 8px 10px;
        border: 1px solid #d3d3d3;
        margin-right: 10px;

        img {
          width: 14px;
          height: 14px;
        }
      }

      .question-btn-delete {
        @include surveyViewDetailsBtn;
        color: #d03238;
        font-style: italic;
        padding: 8px 16px;
        border: 1.5px solid #d03238;
        background: #fdd3d3;
        display: flex;
        gap: 3px;
      }

      .question-btn-clone,
      .question-btn-report {
        @include surveyViewDetailsBtn;
        padding: 8px 15px 8px 10px;
        border: 1px solid #d3d3d3;
        display: flex;
        gap: 3px;
      }

      .qtn-btn-inactive {
        @include surveyViewDetailsBtn;
        color: #d03238;
        font-style: italic;
        padding: 8px 16px;
        border: 1.5px solid #d03238;
        background: #fdd3d3;

        img {
          width: 19px;
          margin-right: 3px;
        }
      }

      .qtn-btn-active {
        @include surveyViewDetailsBtn;
        color: #2d7d46;
        font-style: italic;
        padding: 8px 16px;
        border: 1.5px solid #2d7d46;
        background: #d3f5dd;

        img {
          width: 19px;
          margin-right: 3px;
        }
      }

      .Icon {
        margin-right: 5px;
      }
      // span {
      //   font-weight: 400;
      //   font-size: 15px;
      //   font-style: italic;
      //   color: gray;
      // }
    }
  }

  .divider {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid gray;
  }

  .editBtn-container {
    .editBtn-container-hrLine {
      margin-top: 0px;
      margin-bottom: 15px;
    }

    .editBtn-container-btn {
      display: flex;
      justify-content: center;

      .editBtn-container-btn-cancel {
        width: 153px;
        border-radius: 8px;
        border: 1px solid #d3d3d3;
        background-color: #fff;
        color: #414241;
      }

      .editBtn-container-btn-update {
        width: 153px;
        border-radius: 8px;
        margin-left: 20px;
      }
    }
  }

  .eventDate {
    margin-top: 20px;
  }

  .add-question-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    padding: 10px;
    text-transform: none;
    font-weight: normal;
    border: 1px solid lightgray;
    cursor: pointer;

    &:hover {
      background-color: #414241;
      color: white;
    }
  }

  .add-question-button:disabled {
    cursor: pointer;
  }

  .question-box-container {
    display: flex;
    gap: 15px;
    margin-top: 20px;

    .dragQuestionNumber {
      display: flex;
      gap: 10px;
      margin-top: 18px;
    }

    .question-box {
      border: 1px solid #ddd;
      border-radius: 8px;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 950px;
      height: fit-content;

      &.collapsed .question-content {
        display: none;
      }

      &.expanded .question-content {
        display: flex;
      }

      .question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f9f9f9;
        border-bottom: 1px solid #ddd;
        padding: 15px;

        .collapsedQuestionSummary {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .collapsedQuestFirst {
            display: flex;
            align-items: center;
            gap: 15px;
          }
          .question_type_main {
            display: flex;
            align-items: center;
          }

          .question_type {
            white-space: nowrap;
          }
        }

        .drodownTrashSurvey {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .drag-icon {
          cursor: move;
        }
        button {
          border: none;
          background: none;
          cursor: pointer;
        }
      }

      .showError {
        border: 1px solid red;
      }

      .question-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;

        .form-control {
          padding: 10px;
          border-radius: 10px;
        }

        .questionsSelect {
          display: flex;
          gap: 15px;
          margin-top: 10px;
        }

        .question-options {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .questionSwitches {
            display: flex;
            align-items: center;
            gap: 70px;

            .custom-switch {
              .form-check-input {
                width: 40px;
                height: 20px;
                border-radius: 20px;
                background-color: #007bff;
                border: none;
                position: relative;
                appearance: none;

                &:checked {
                  background-color: #007bff;
                  &::after {
                    content: "\2714";
                    color: white;
                    font-size: 12px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                &::after {
                  content: "";
                  display: block;
                  width: 14px;
                  height: 14px;
                  background-color: white;
                  border-radius: 50%;
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  transition: left 0.2s;
                }
              }
            }
          }

          h6 {
            font-weight: bold;
          }

          .options {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            .option {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 20px;

              .form-control {
                width: 300px;
              }
              .option-label {
                min-width: 75px;
                font-weight: normal;
              }
            }
          }
          .add-option-button {
            align-self: flex-end;
            color: #007bff;
            display: flex;
            align-items: center;
            gap: 3px;

            &:hover {
              text-decoration: underline;
            }
          }
          .sub-question {
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 15px;
            margin-top: 10px;

            .sliderFirstRow {
              display: flex;
              align-items: center;
              gap: 20px;
              margin-bottom: 20px;
              margin-top: 15px;

              .sliderQuestion {
                flex: 1;
              }
            }

            .sub-question-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
            }
          }
        }

        .errorMessageQuestion {
          color: red;
          font-size: 12px;
          margin-top: 5px;
        }

        .errorMessageOptions {
          color: red;
          font-size: 12px;
          text-align: center;
        }
      }

      .question-summary {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          &:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.footerSurveyButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  padding: 15px 30px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.05);
  gap: 10px;

  button.btn.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
    padding: 10px 20px;
  }

  .save-proceed-button {
    background-color: #414241;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px 20px;
    color: white;
    text-align: center;
    text-transform: none;

    &:hover {
      background-color: #333;
    }
  }
}
