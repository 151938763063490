.signUp {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding-top: 25px;

  .grittyLogo {
    img {
      object-fit: contain;
      height: auto;
      width: 150px;
    }
  }

  .signUpbox {
    width: 700px;
    border: none;
    outline: none;
    border-radius: 16px;
    // background: var(--White, #fff);

    /* Card Shadow */
    box-shadow: 0px 1px 5px 0px rgba(109, 109, 109, 0.75);
    padding: 20px;
    margin-bottom: 50px;

    .signUpHeader {
      text-align: center;

      h1 {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .signUpInfo {
      margin-top: 25px;

      p {
        font-weight: bold;

        span {
          font-weight: normal;
          color: gray;
          font-style: italic;
        }
      }

      form {
        margin-top: 20px;

        .worksiteInfo {
          display: flex;
          flex-direction: column;
          gap: 40px;
          align-items: stretch;

          .captcha {
            margin-top: 10px;
            .error {
              color: red;
              display: flex;
              align-items: center; /* Center the icon with the text */
              font-size: 12px;
              font-weight: normal;
              margin-top: 4px;
              text-decoration: none;
              gap: 4px;

              p {
                margin-left: 10px; /* Space between icon and text */
              }
            }

            .captchaError {
              border: 1px solid red;
              width: 46%;
            }
          }

          .inputTraining {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-top: 30px;

            .inputTrainingLine {
              display: flex;
              flex-direction: column;
              gap: 8px;

              span {
                font-weight: bold;
              }

              p {
                color: gray;
                font-size: 14px;
              }
            }

            .inputError {
              input {
                border: 1px solid red;
              }
            }

            .inputWrapper {
              display: flex;
              flex-direction: column;
              height: 40px;

              .error {
                color: red;
                display: flex;
                align-items: center; /* Center the icon with the text */
                font-size: 12px;
                font-weight: normal;
                margin-top: 4px;
                text-decoration: none;
                gap: 4px;

                p {
                  margin-left: 10px; /* Space between icon and text */
                }
              }
            }

            input {
              width: 315px;
            }

            p {
              font-size: 16px;
              color: #666;
              font-weight: normal;
            }
          }

          .dropdownName {
            display: flex;
            gap: 20px;
            align-items: center;

            .inputError {
              select {
                border: 1px solid red;
              }
            }

            .dropdownWrapper {
              display: flex;
              flex-direction: column;
              height: 40px;

              .error {
                color: red;
                display: flex;
                align-items: center; /* Center the icon with the text */
                font-size: 12px;
                font-weight: normal;
                margin-top: 4px;
                text-decoration: none;
                gap: 4px;

                p {
                  margin-left: 10px; /* Space between icon and text */
                }
              }
            }

            select {
              width: 315px;
              border: 1px solid gray;
              border-radius: 5px;
              padding: 7px;
              color: #666;

              // option{
              //   height: 300px;
              // overflow-y: auto;
              // }
            }
          }
        }

        .notVerified {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .verified {
          display: flex;
          flex-direction: column;
          gap: 4px;

          span {
            text-align: right;
            width: 315px;
            font-size: 12px;
            color: green;
          }
        }

        .inputEmail-container {
          margin-bottom: 70px;

          .inputEmail {
            margin-top: 40px;
            position: relative;
            // margin-bottom: 70px;

            .inputError {
              input {
                border: 1px solid red;
              }
            }

            .inputWrapper {
              display: flex;
              flex-direction: column;
              height: 40px;

              .error {
                color: red;
                display: flex;
                align-items: center; /* Center the icon with the text */
                font-size: 12px;
                font-weight: normal;
                margin-top: 4px;
                text-decoration: none;
                gap: 4px;

                p {
                  margin-left: 10px; /* Space between icon and text */
                }
              }
            }

            .checkmark {
              position: absolute;
              left: 290px;
              top: 8px;
              object-fit: contain;
              height: auto;
              width: 20px;
            }

            input {
              width: 315px;
            }

            p {
              text-decoration: underline;
              color: #4a76e6;
              font-weight: bold;
              font-size: 14px;
              cursor: pointer;
            }
          }

          .inputEmail-subtitle {
            margin-top: 10px;
          }
        }

        .inputName {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          align-items: center;

          .inputError {
            input {
              border: 1px solid red;
            }
          }

          .inputWrapper {
            display: flex;
            flex-direction: column;
            // height: 40px;

            .error {
              color: red;
              display: flex;
              align-items: center; /* Center the icon with the text */
              font-size: 12px;
              font-weight: normal;
              margin-top: 4px;
              gap: 4px;

              p {
                margin-left: 10px; /* Space between icon and text */
              }
            }
          }

          input {
            width: 315px;
          }
        }

        input {
          border: 1px solid gray;
          border-radius: 5px;
          padding: 7px;
        }

        .signUpSubmit {
          margin-top: 30px;

          .worksiteBtn {
            display: flex;
            gap: 20px;

            button {
              &:first-child {
                background-color: #fff;
                color: black;
                border: 1px solid gray;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
              }
            }
          }

          p {
            font-weight: normal;
            font-size: 13px;

            span {
              text-decoration: underline;
              color: #4a76e6;
              font-weight: bold;
              cursor: pointer;
            }
          }

          .isVerify {
            margin-top: 20px;
          }

          button {
            width: 100%;
            margin-top: 15px;
            background-color: #414241;
            border-radius: 5px;
            border: none;
            outline: none;
            padding: 10px;
            color: white;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .signUp {
    padding-top: 10px;

    .grittyLogo img {
      width: 100px;
    }

    .signUpbox {
      width: 90%;
      padding: 10px;

      .signUpHeader h1 {
        font-size: 18px;
      }

      .signUpInfo {
        margin-top: 10px;

        p {
          font-size: 14px;
        }

        form {
          margin-top: 10px;

          .worksiteInfo {
            gap: 20px;

            .captcha .error {
              font-size: 10px;
            }

            .captcha {
              .captchaError {
                width: 273px;
                height: 70px;
              }
            }

            .inputTraining {
              flex-direction: column;
              // align-items: flex-start;
              gap: 10px;
              margin-top: 20px;

              .error {
                font-size: 10px;
              }

              .inputTrainingLine {
                align-self: flex-start;
              }

              input {
                // width: 500px;
              }
            }

            .dropdownName {
              flex-direction: column;
              gap: 16px;

              .dropdownWrapper .error {
                font-size: 10px;
              }

              select {
                width: 100%;
              }
            }
          }

          .notVerified {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            input {
              width: 230px;
            }
          }

          .inputEmail-container {
            width: 100%;
            .inputEmail {
              margin-top: 0px;
              // margin-bottom: 50px;
              // display: flex;
              // margin-top: 20px;
              // align-items: center;
              // margin-left: 17px;

              .inputWrapper {
                width: 100%;

                input {
                  width: 100%;
                }
                .error {
                  font-size: 10px;
                  margin-left: 10px;
                }
              }

              p {
                // margin-top: 10px;
                // margin-left: 26px;
              }
            }
          }

          .inputName {
            // flex-direction: column;
            gap: 10px;
            width: 100%;
            .inputWrapper {
              width: 100%;

              input {
                width: 100%;
              }
              .error {
                font-size: 10px;
              }
            }
          }

          .signUpSubmit {
            .worksiteBtn {
              flex-direction: column;
              gap: 10px;

              button {
                &:first-child {
                  font-size: 12px;
                }
              }
            }

            p {
              font-size: 12px;
              line-height: 20px;
            }

            button {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// @media (min-width: 414px) and (max-width: 600px) {
//   .notVerified {
//     input {
//       margin-left: 20px;
//     }
//   }
// }

// @media (min-width: 412px) and (max-width: 600px) {
//   .notVerified {
//     input {
//       margin-left: 22px;
//     }
//   }
// }

// @media (min-width: 390px) and (max-width: 600px) {
//   .notVerified {
//     input {
//       margin-left: 10px;
//     }
//   }
// }

// @media (min-width: 430px) and (max-width: 600px) {
//   .notVerified {
//     input {
//       margin-left: 22px;
//     }
//   }
// }
