.customDropdown {
    position: relative;
    width: 315px;
    height: 40px;
  
    .inputError {
      button {
        border: 1px solid red;
      }
    }
  
    .dropdownWrapper {
      display: flex;
      flex-direction: column;
  
      .error {
        color: red;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: normal;
        margin-top: 4px;
        gap: 4px;
      }
    }
  
    .dropdownButton {
      width: 100%;
      padding: 9px;
      border: 1px solid gray;
      border-radius: 5px;
      background-color: #fff;
      color: #666;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
  
      .dropdownIcon {
        font-size: 13px;
        color: #666;
      }
    }
  
    .dropdownContent {
      position: absolute;
      top: 100%;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 100;
      border-radius: 4px;
      max-height: 120px;
      overflow-y: auto;
  
      .dropdownOption {
        padding: 10px;
        cursor: pointer;
  
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
  