.member-details-outer-wrap {
  margin: 36px 0 125px;
  .member-details-wrap {
    padding: 22px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(7, 7, 7, 0.1);
    border: 1px solid gray;
  }

  .member-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    .member-action-buttons {
      .accepted-action-buttons{
        display: flex;
        align-items: center;
        gap: 15px;

        .edit-button, .disable-button, .enable-button{
          border-radius: 20px;
          text-transform: none;
          font-weight: normal;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .disable-button{
          border: 1px solid red;
          background-color: transparent;
          color: red;

          &:hover{
            background-color: red;
            color: white;
          }
        }

        .enable-button{
          border: 1px solid green;
          background-color: lightgreen;
          color: green;

          &:hover{
            background-color: green;
            color: white;
          }
        }

        .edit-button{
          &:hover{
            background-color: #414241;
            color: white;
          }
        }
      }
    }

    .member-header-main{
      display: flex;
      align-items: center;
      gap: 10px;
    }

    h1 {
      font-weight: bold;
      font-size: 17px;
    }
    .back-icon {
      cursor: pointer;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 3px;
      font-size: 25px;
      &:hover {
        background-color: #414241;
        color: white;
      }
    }
  }

  .member-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .acceptRjctBtn{
      display: flex;
      align-items: center;
      gap: 15px;

      button{
        border-radius: 20px;
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        text-align: center;

        &:first-child{
          border: 1px solid lightgray;

          &:hover{
            background-color: #414241;
            color: white;
          }
        }
      }
    }

    .member-info-icon{
      img{
        object-fit: contain;
        height: auto;
        width: 50px;
      }
    }

    .member-info-main{

      .member-info-head{
        display: flex;
        align-items: center;
        gap: 10px;

        .member-avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #414241;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 24px;
          margin-right: 15px;
        }
        .member-details {
          h2 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .member-email {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            color: gray;
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .divider {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid gray;
  }

  .member-additional-info {
    .rejection-reason{
       margin-top: 30px;
       margin-bottom: 15px;
       min-width: 100%;
       border: 1px solid red;
       border-radius: 10px;
       text-align: left;
       padding: 15px;
       background-color: #fff4f4;

       strong{
        font-weight: 600;
       }

       p{
        margin-top: 10px;
        color: gray;
        text-wrap: wrap;
        word-wrap: break-word;
       }
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        strong {
          font-weight: bold;
        }
      }
    }

    .info-row-end{
      display: flex;
      justify-content: space-between;
      width: 50%;
      margin-top: 40px;

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        strong {
          font-weight: bold;
        }
      }

    }
  }
}
