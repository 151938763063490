.options-card {
    position: absolute;
    top: 30px;
    right: 0;
    width: 120px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    display: flex;
    flex-direction: column;
  
    button {
      padding: 10px;
      font-size: 14px;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;
  
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  