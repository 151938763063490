.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;

    .popup-close {
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      cursor: pointer;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin: 20px 0;
      font-size: 13px;
      line-height: 20px;
    }

    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
    }

    .popup-buttons {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: center;
      width: 100%;

      button {
        padding: 10px 25px;
        border-radius: 5px;
        border: none;
        cursor: pointer;

        &:first-of-type {
          background-color: #fff;
          color: #414241;
          border: 1px solid #414241;
        }

        &:last-of-type {
          background-color: #414241;
          color: #fff;
        }
      }
    }
  }
}